import { styled } from "styled-components";

import TextField from "components/TextField";

export const FormElement = styled(TextField)`
    && {
        display: inline;
        height: 0;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 0;
        z-index: -1;
    }
`;
