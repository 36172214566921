import React, { ReactElement } from "react";

import ChoiceElementProps from "components/ChoiceElement/ChoiceElementProps";

import { FormElement } from "./HoneyPot.styled";

const HoneyPot = ({ control, id }: ChoiceElementProps): ReactElement => {
        return (
        <FormElement
            id={id}
            elementName="hp012345"
            label="Do not use this field"
            aria-label="Do not use this field"
            control={control}
            tabIndex={-1}
            autoComplete="off"
        />
    );
};

export default React.memo(HoneyPot);
